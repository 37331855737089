import 'core-js/stable'
import 'regenerator-runtime/runtime'
import debounce from 'lodash/debounce'

/**
 * @callback QueryCallback
 * @param {Element}
 */

/**
 * @param {string|Iterable|Object} [sel]
 * @param {Function} [cb]
 * @param {boolean} [returnOriginal]
 * @returns {Generator<Element|any>}
 */
const gen = function * (sel, cb, returnOriginal = false) {
    sel = typeof(sel) === 'string' ? [sel] : sel ?? []
    let count = 0
    if (Symbol.iterator in Object(sel)) {
        for (let el of sel) {
            if ((el = transform(el, count, cb, count++, returnOriginal)) !== null) {
                yield el
            }
        }
    } else {
        for (let [key, el] of Object.entries(sel)) {
            if ((el = transform(el, key, cb, count++, returnOriginal)) !== null) {
                yield el
            }
        }
    }
}

const transform = (val, key, cb, count, returnOriginal)  => {
    const res = cb ? cb(val, count === key ? null : key, count) : val
    return (returnOriginal ? val : res) ?? null
}

/**
 * @param {string|Iterable|Object} [sel]
 * @param {ParentNode|QueryCallback} [cb]
 * @param {ParentNode} [root]
 * @returns {array}
 */
const each = (sel, cb, root) => {
    if (typeof(cb) !== 'function') {
        root = cb
        cb = null
    }
    if (typeof(sel) === 'string') {
        sel = (root || document).querySelectorAll(':scope ' + sel)
    } else if (sel instanceof Element) {
        sel = [sel]
    }
    return [...gen(sel, cb, true)]
}

/**
 * @param {Element} el
 * @param {string|Iterable|Object} [selectors]
 * @param {QueryCallback} [cb]
 * @return {array}
 */
const nodes = (el, selectors = undefined, cb = undefined) => {
    if (selectors) {
        return map(selectors, selector => each(selector, cb, el).length || each(selector, cb))
    }
    return each([el], cb)
}

const request = (url, method, data = {}, el = undefined) => {
    const _ = {
        valid: true,
        post: {},
        get: new URLSearchParams(),
        append: ([name, value, valid]) => {
            _.valid = _.valid && valid
            _.post[name] = value
            _.get.append(name, value = cast(value))
            url = (url ?? '').replaceAll(`[${name}]`, value)
        },
        get body () {
            return method !== 'GET' ? JSON.stringify(_.post) : null
        },
        get url () {
            if (method !== 'GET') {
                return url
            }
            return url + (url.includes('?') ? '' : '?') + _.get
        }
    }

    map(data, (v, k) => {
        if (Number.isInteger(Number(k))) {
            nodes(el, v, node => {
                if (node.tagName === 'FORM') {
                    map(map(new FormData(node), ([name, value]) => {
                        return [name, value, node.elements[name].reportValidity()]
                    }), _.append)
                } else {
                    map([[node.name, node.value, node.reportValidity()]], _.append)
                }
            })
        } else {
            _.append([k, v, true])
        }
    })

    const req = (then, options = {}) => {
        const promise = req.fetch(options).then(res => {
            if (res.status !== 200) {
                throw new Error(`${_.url}: fetch status !== 200`)
            }
            return res.json()
        })
        return then ? promise.then(then) : promise
    }

    /**
     * follows Router::toString -> Route::toJson
     * 1. convert response to json
     * 2. check errors attribute
     * 3. retrieve data attribute
     */
    req.data = (then, options) => req(json => {
        for (const error of json.errors ?? []) {
            switch (error.type) {
                case 'reload': (href => window.location.href = href)(...error.args)
            }
        }
        return then(json.data)
    }, options)

    req.fetch = (options = {}) => fetch(_.url, {
        method: method,
        mode: 'same-origin',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: _.body,
        ...options
    })

    req.url = _.url
    req.valid = _.valid
    return req
}

/**
 * @param {string|Iterable|Object} [data]
 * @param {Function} [cb]
 * @returns {array}
 */
const map = (data, cb) => {
    return [...gen(typeof(data) === 'string' ? [data] : data, cb, false)]
}
map.kv = cb => (v, k) => cb(k ?? v, k === null ? null : v)

/**
 * @param {Element} el
 * @param {string} prop
 * @param {boolean} [asNumber]
 * @returns {string}
 */
const css = (el, prop, asNumber)  => {
    const value = window.getComputedStyle(el).getPropertyValue(prop)
    return asNumber ? parseFloat(value) : value
}

/**
* @param {Element} el
* @param {string} token
* @param {boolean} [toggle]
* @returns {boolean}
*/
css.class = (el, token, toggle) => {
    if (toggle === undefined) {
        return el.classList.contains(token)
    }
    toggle ? el.classList.add(token) : el.classList.remove(token)
    return toggle
}

/**
 * @param {Element} el
 * @param {string|boolean} [cl]
 * @param {boolean} [enable]
 * @returns {boolean}
 */
const toggle = (el, cl, enable) => {
    if (typeof(cl) === 'string') {
        return css.class(el, cl, (enable ?? !css.class(el, cl)))
    }
    enable = cl === undefined ? css(el, 'display') === 'none' : cl
    el.style.display = enable ? '' : 'none'
    return enable
}

/**
 * @param {Element} el
 * @returns {{top: int, left: int}}
 */
const offset = el => {
    const box = el.getBoundingClientRect()
    return {
        top: box.top + window.pageYOffset - document.documentElement.clientTop,
        left: box.left + window.pageXOffset - document.documentElement.clientLeft
    }
}

/**
 * @param {EventTarget} el
 * @param {String} name
 * @param {function} handler
 * @param [options]
 * @return {function}
 */
const on = (el, name, handler, options) => {
    const listener = options ? debounce(handler, options) : handler
    if (name === 'mutate') {
        const observer = new MutationObserver(mutations => {
            el.dispatchEvent(new CustomEvent('mutate', {
                detail: mutations
            }))
        })
        observer.observe(el, options ?? {childList: true, subtree: true, attributes: false})
    }

    el.addEventListener(name, e => {
        if (listener(e) === false) {
            e.stopPropagation()
            e.preventDefault()
            e.returnValue = false
        }
    })
    return handler
}
on.mutate = (el, ...args) => on(el, 'mutate', ...args)
on.click = (el, ...args) => on(el, 'click', ...args)
on.change = (el, ...args) => on(el, 'change', ...args)
on.scroll = (...args) => on(window, 'scroll', ...args)

const submit = (form, values) => {
    for (const name in values || {}) {
        let input = form.elements[name]
        if (!input) {
            input = document.createElement('input')
            input.setAttribute('name', name)
            input.setAttribute('type', 'hidden')
            form.appendChild(input)
        }
        input.setAttribute('value', input.value = cast(values[name]))
    }
    return form.submit()
}

const cast = value => {
    if (value === true) {
        return 1
    } else if (value === false) {
        return 0
    }
    return value
}

// http://blog.greweb.fr/2012/02/bezier-curve-based-easing-functions-from-concept-to-implementation/
const easeInOutCubic = t => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t -2) * (2 * t - 2) + 1

// we use requestAnimationFrame to be called by the browser before every repaint
// if the first argument is an element then scroll to the top of this element
// if the first argument is numeric then scroll to this location
const scroll = (to, duration) => {
    duration = duration || 500

    const position = (start, end, elapsed, duration) => {
        return elapsed > duration ? end : start + (end - start) * easeInOutCubic(elapsed / duration)
    }
    const start = window.pageYOffset
    const clock = Date.now()
    const frame = window.requestAnimationFrame || (fn => window.setTimeout(fn, 15))
    const step = () => {
        const elapsed = Date.now() - clock
        window.scroll(0, position(start, to, elapsed, duration))
        if (elapsed <= duration) {
            frame(step)
        }
    }
    step()
}

/**
 * @param {?string} url
 * @param {Function} [success]
 */
const ajax = (url, success) => {
    if (url === null) {
        success()
        return
    }
    const req = new XMLHttpRequest()
    req.open('GET', url, true)
    req.onload = () => {
        success(JSON.parse(req.responseText).data, req)
    }
    req.overrideMimeType('application/json')
    req.setRequestHeader('Accept', 'application/json')
    req.send()
}

/**
 * @param {Element} el
 * @param {Object} [attrs]
 * @returns {Element}
 */
const clone = (el, attrs) => {
    const cloned = document.createElement(el.tagName)
    attrs = map(Object.entries(attrs ?? {}), ([name, value]) => ({name:name, value:value}))
    cloned.text = el.innerHTML
    each([...el.attributes, ...attrs], ({name, value}) => {
        if (cloned.hasAttribute(name) || (cloned[name] ?? null)) {
            return
        }
        typeof(value) === 'string' ? cloned.setAttribute(name, value) : cloned[name] = value
    })
    return cloned
}

/**
 * @param {Element} el
 * @param {string} type
 * @param {Function} callback
 * @returns {Element}
 */
const recursive = (el, type, callback) => {
    if (el.tagName === type.toUpperCase()) {
        callback(el)
    } else {
        each(el.childNodes, child => recursive(child, type, callback))
    }
    return el
}

/**
 * #id: {string, append|prepend|replace}
 *
 * @param {Element} [el]
 * @param {?string|string[]|object} [data]
 * @return {Promise}
 */
const load = (el, data) => {
    const scripts = []
    const nodes = [el]
    const blocks = {}

    map(data, (v, k) => {
        k = Number.isInteger(Number(k)) ? '' : k;
        (blocks[k] = blocks[k] ?? {
            target: k === '' ? el : k,
            content: [],
            insert: k !== '' // string => append, numeric => replace children
        }).content.push(v)
    })

    map(blocks, block => {
        block.content = block.content.join("\n")
        each(block.target, el => {
            nodes.indexOf(el) < 0 && nodes.push(el)
            block.insert ? el.insertAdjacentHTML('beforeend', block.content) : el.innerHTML = block.content

            recursive(el, 'SCRIPT', script => {
                scripts.push(new Promise(resolve => script.parentNode.replaceChild(
                    clone(script, {onload: _ => resolve(script)}),
                    script
                )))
            })
        })
    })

    return Promise.all(scripts).then(_ => {
        for (const args of loadAlways) {
            for (const node of nodes) {
                args && (typeof(args) === 'function' ? args(node) : each(...args, node))
            }
        }
        while (loadOnce.length) {
            let callBack = loadOnce.shift()
            callBack && callBack()
        }
    })
}

const loadOnce = []
const loadAlways = []
load.on = (args, once = false) => {
    (once ? loadOnce : loadAlways).push(args)
    return args
}

load.json = (url, options = {}) => {
    if (typeof(url) === 'string') {
        options.url = url
    } else {
        options = {...url, ...options}
    }
    url = options.url ?? ''

    url += (params instanceof URLSearchParams ? `?${params}` : '')

    return new Promise(resolve => {
        fetch(url, {
            method: method,
            mode: urlOrOptions.mode ?? 'no-cors',
            cache: urlOrOptions.cache ?? 'no-cache',
            body: params instanceof FormData ? params : null,
        }).then(res => {
            if (res.status !== 200) {
                throw new Error(`${url}: fetch status !== 200`)
            }
            res.json().then(json => {
                resolve(json?.data)
            })
        })
    })
}

const script = (src, onload) => {
    const script = document.createElement('script');
    script.src = src
    onload && (script.onload = onload)
    document.head.appendChild(script)
}

/**
 * @param {Element} el
 * @param {String} [target]
 * @returns {string}
 */
const target = (el, target) => [
        ['[value]', el.value],
        ['[id]', el.id],
        ['//', '/']
    ].reduce(
    (str, [search, replace]) => str.replace(search, replace),
    target ?? el.getAttribute('data-target') ?? el.getAttribute('href') ?? ''
)

const time = timeStamp => {
    const days = parseInt('' + timeStamp / 86400)
    timeStamp = {
        timestamp: parseInt(timeStamp),
        d: days,
        h: parseInt('' + timeStamp % (60 * 60 * 24) / (60 * 60)),
        m: parseInt('' + timeStamp % (60 * 60) / 60),
        s: timeStamp % 60
    }
    return {...timeStamp,
        D: ("00" + timeStamp.d).slice(-2),
        H: ("00" + timeStamp.h).slice(-2),
        M: ("00" + timeStamp.m).slice(-2),
        S: ("00" + timeStamp.s).slice(-2),
    }
}

const interval = (handler, from, to = 0, timeout = 1000) => {
    return new Promise(resolve => {
        const update = () => {
            handler && handler(from)
            from -= 1
            if (from < to) {
                clearInterval(inter)
                resolve()
            }
        }
        update()
        const inter = setInterval(update, timeout)
    })
}

export {
    map,
    css,
    toggle,
    offset,
    on,
    submit,
    each,
    nodes,
    request,
    scroll,
    ajax,
    load,
    target,
    time,
    interval,
    script,
}